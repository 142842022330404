// Custom.scss
// Option B: Include parts of Bootstrap

@font-face {
  font-family: 'ALDI SUED Office';
  src: local('ALDI SUED Office Regular'), local('ALDISUEDOffice-Regular'),
    url('./fonts/ALDISUEDOffice-Regular.woff2') format('woff2'),
    url('./fonts/ALDISUEDOffice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALDI SUED Office';
  src: local('ALDI SUED Office Bold'), local('ALDISUEDOffice-Bold'),
    url('./fonts/ALDISUEDOffice-Bold.woff2') format('woff2'),
    url('./fonts/ALDISUEDOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
// site colours
$aldi-blue: #001E78;
$aldi-dark-blue: #00005F;
$aldi-light-blue: #44D0F3;
$aldi-orange: #FF7800;
$aldi-white: #FFF;
$aldi-black: #111;
$aldi-dark-gray: #999;
$aldi-light-gray: #CCC;

$body-bg: $aldi-blue;
$body-color: $aldi-white;
$body-secondary-bg: $aldi-dark-blue;
$body-secondary-color: $aldi-light-blue;
$body-tertiary-bg: $aldi-white;
$body-tertiary-color: $aldi-black;

$font-family-base: 'ALDI SUED Office', san-serif;
$font-size-root: 18px;
//$font-size-base: 1rem;
$line-height-base: 1.2;

$input-bg: $aldi-white;
$input-disabled-color: $aldi-dark-gray;
$input-disabled-bg: $aldi-light-gray;
$input-disabled-border-color: null;

$input-color: $aldi-black;
$input-border-color: null;
$input-border-width: 0;
$input-box-shadow: none;

$input-placeholder-color: $aldi-dark-gray;
$input-plaintext-color: $aldi-black;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1.75rem;
//$input-padding-y: $input-btn-padding-y;
$input-padding-x: 0.75rem;

$link-color: $aldi-light-blue;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Bootstrap Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

// Bootstrap Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Bootstrap Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

$aldi-colours: (
  "aldi-blue": $aldi-blue,
  "aldi-dark-blue": $aldi-dark-blue,
  "aldi-light-blue": $aldi-light-blue,
  "aldi-orange": $aldi-orange,
);

:root {

  @each $color,
  $value in $aldi-colours {
    --c-#{$color}: #{$value};
  }

  --inner-gutter: 1.25rem;

}

::-webkit-scrollbar {
  display: none;
}

html * {
  scrollbar-width: none;
  overscroll-behavior: none;
}

@each $color,
$value in $aldi-colours {

  // background colours
  .bg-#{$color} {
    background-color: var(--c-#{$color});
  }

  // text-colours
  .text-#{$color} {
    color: var(--c-#{$color});
  }

  // svg path fill
  .fill-#{$color},
  .fill-bg-#{$color} {
    >path {
      fill: var(--c-#{$color});
    }
  }

  // button colours
  .btn-#{$color} {
    @include button-variant($value, $value);
  }

  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }

  .border-#{$color} {
    border: 2px solid $value;
  }

  .border-top-#{$color} {
    border-top: 2px solid $value;
  }

  .border-bottom-#{$color} {
    border-bottom: 2px solid $value;
  }

  .border-right-#{$color} {
    border-right: 2px solid $value;
  }

  .border-left-#{$color} {
    border-left: 2px solid $value;
  }
}

.mdvh-100 {
  min-height: 100dvh;
}

.section-content {
  padding-top: 5rem;
}

.section-content-sm {
  padding-top: 3rem;
}

.mnh-5 {
  min-height: 5rem;
}

.mxh-8 {
  max-height: 8rem;
}
